import { ContainerProps, Container, Box } from '@mui/material'
import React from 'react'
import { extendableComponent } from '@graphcommerce/next-ui/Styles'

export type FooterProps = {
  storeSwitcher?: React.ReactNode
  socialLinks?: React.ReactNode
  customerService?: React.ReactNode
  aboutLinks?: React.ReactNode
  address?: React.ReactElement
  copyright?: React.ReactElement
} & Omit<ContainerProps, 'children'>

const { classes, selectors } = extendableComponent('Footer', [
  'root',
  'social',
  'storeSwitcher',
  'support',
  'about',
  'address',
  'copyright',
] as const)

export function Footer(props: FooterProps) {
  const {
    socialLinks,
    storeSwitcher,
    customerService,
    aboutLinks,
    address,
    copyright,
    sx = [],
    ...containerProps
  } = props

  return (
    <Container
      sx={[
        (theme) => ({
          gridTemplateColumns: 'auto',
          borderTop: `1px solid ${theme.palette.divider}`,
          display: 'grid',
          alignItems: 'center',
          padding: `${theme.spacings.lg} ${theme.page.horizontal} ${theme.page.vertical}`,
          justifyItems: 'center',
          gridTemplateAreas: `
            'about'
            'address'
            'switcher'
            'social'
            'links'
            'support'
          `,
          gap: theme.spacings.md,
          '& > *': { maxWidth: 'max-content' },

          [theme.breakpoints.up('md')]: {
            gap: theme.spacings.sm,
            gridTemplateAreas: `
              'about address social switcher switcher'
              'links links links links support'
            `,
            justifyItems: 'start',
            padding: `${theme.page.vertical} ${theme.page.horizontal}`,
            gridTemplateColumns: 'auto auto auto auto',
            gridTemplateRows: 'auto',
            justifyContent: 'space-between',
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      maxWidth={false}
      className={classes.root}
      {...containerProps}
    >
      <Box
        sx={(theme) => ({
            display: 'grid',
            justifyContent: 'start',
            gridAutoFlow: 'column',
            gridArea: 'address',
            gap: {xs: `0 ${theme.spacings.xs}`, md: `0 ${theme.spacings.xs}`},
            '& > *': {
              minWidth: 'min-content',
            },
            [theme.breakpoints.up('md')]: {
              gridAutoFlow: 'row',
            }
          }
        )}
        className={classes.address}
      >
        {address}
      </Box>
      <Box
        sx={(theme) => ({
            display: 'grid',
            justifyContent: 'start',
            gridAutoFlow: {xs: 'row', sm: 'column'},
            textAlign: {xs: 'center', sm: 'left'},
            gridArea: 'about',
            gap: {xs: `0 ${theme.spacings.xs}`, md: `0 ${theme.spacings.xs}`},
            '& > *': {
              minWidth: 'min-content',
            },
            [theme.breakpoints.up('md')]: {
              textAlign: 'left',
              gridAutoFlow: 'row',
            }
          }
        )}
        className={classes.about}
      >
        {aboutLinks}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'grid',
          justifyContent: 'start',
          gridAutoFlow: 'column',
          gridArea: 'social',
          gap: { xs: `0 ${theme.spacings.xs}`, md: `0 ${theme.spacings.xs}` },
          '& > *': {
            minWidth: 'min-content',
          },
        })}
        className={classes.social}
      >
        {socialLinks}
      </Box>
      <Box
        sx={(theme) => ({
          gridArea: 'switcher',
          justifySelf: 'end',
          [theme.breakpoints.down('md')]: {
            justifySelf: 'center',
          },
        })}
        className={classes.storeSwitcher}
      >
        {storeSwitcher}
      </Box>
      <Box
        sx={(theme) => ({
          gridArea: 'support',
          justifySelf: 'flex-end',
          [theme.breakpoints.down('md')]: {
            justifySelf: 'center',
          },
        })}
        className={classes.support}
      >
        {customerService}
      </Box>
      <Box
        sx={(theme) => ({
          typography: 'body2',
          display: 'grid',
          gridAutoFlow: 'column',
          alignContent: 'center',
          alignItems: 'center',
          gridArea: 'links',
          gap: theme.spacings.sm,
          marginTop: theme.spacings.sm,
          [theme.breakpoints.down('md')]: {
            gridAutoFlow: 'row',
            textAlign: 'center',
            gap: `8px`,
          },
        })}
        className={classes.copyright}
      >
        {copyright}
      </Box>
    </Container>
  )
}

Footer.selectors = selectors
