import { Image } from '@graphcommerce/image'
import { StoreSwitcherButton } from '@graphcommerce/magento-store'
import { Footer as FooterBase } from '../Footer/Footer'
import { Trans } from '@lingui/react'
import {Box, Button, IconButton, Link} from '@mui/material'
import { FooterQueryFragment } from './FooterQueryFragment.gql'
import {RichText} from '@graphcommerce/graphcms-ui'
import React from 'react'
import {GuestNewsletter} from '../Newsletter/GuestNewsletter'

export type FooterProps = FooterQueryFragment

export function Footer(props: FooterProps) {
  const { footer } = props

  return (
    <FooterBase
      aboutLinks={
        <>
          {footer?.aboutLinks?.map((link) => (
            <Link key={link.title} href={link.url} color='textPrimary' underline='always' sx={{
              fontSize: '16px'
            }}>
              {link.title}
            </Link>
          ))}
        </>
      }
      address={
        <Box sx={(theme) => ({
          width: '100%',
          textAlign: 'center',
          '& p': {
            margin: '0',
            fontSize: '16px'
          },
          '& .MuiBox-root': {
            justifyContent: 'center',
          },
          [theme.breakpoints.up('md')]: {
            paddingLeft: '60px',
            backgroundImage: 'url(/images/icon-poi.svg)',
            backgroundRepeat: 'no-repeat',
            textAlign: 'left',
            '& .MuiBox-root': {
              justifyContent: 'start !important',
            }
          }
          })}>
          {footer &&
            <RichText {...footer.address} />
          }
          <Box
            sx={(theme) => ({
              display: 'grid',
              justifyContent: 'start',
              gridAutoFlow: 'column',
              gap: { xs: `0 ${theme.spacings.xs}`, md: `0 ${theme.spacings.xs}` },
              '& > *': {
                minWidth: 'min-content',
              },
            })}
          >
            <>
              {footer?.socialLinks?.map((link) => (
                <IconButton key={link.title} href={link.url} color='inherit' size='medium' edge='start'>
                  {link.asset ? (
                    <Image
                      layout='fill'
                      src={link.asset.url}
                      width={24}
                      height={24}
                      unoptimized
                      alt={link.title}
                      sx={(theme) => ({
                        filter: theme.palette.mode === 'dark' ? 'invert(100%)' : 'invert(0%)',
                      })}
                    />
                  ) : (
                    link.title
                  )}
                </IconButton>
              ))}
            </>
          </Box>
        </Box>
      }
      socialLinks={
        <Image src='/images/image-footer.jpg' layout='fill' />
      }
      storeSwitcher={
        <Box
          sx={(theme) => ({
            display: 'grid',
            justifyContent: 'start',
            gridAutoFlow: 'row',
            gap: { xs: `0 ${theme.spacings.xs}`, md: `0 ${theme.spacings.xs}` },
            textAlign: 'center',
            '& > *': {
              minWidth: 'min-content',
            },
            '& p': {
              fontSize: '12px',
              margin: '0'
            },
            '& h4': {
              typography: theme.typography.h2,
              fontSize: '32px',
              margin: '0'
            },
            '& form': {
              position: 'relative',
              width: '95vw',
              [theme.breakpoints.up('md')]: {
                width: '20vw'
              }
            },
            '& form fieldset': {
              border: 0,
            },
            '& form input[type="email"]': {
              borderBottom: `1px solid ${theme.palette.divider}`,
              height: '1.15rem'
            },
            '& label': {
              fontSize: '16px'
            },
            '& button': {
              position: 'absolute',
              right: 0,
              color: theme.palette.text.primary,
              padding: '1rem',
              background: 'none'
            },
            '& span': {
              display: 'none'
            },
            [theme.breakpoints.up('md')]: {
              textAlign: 'left'
            }
          })}
        >
          <p>Ningún día sin baño, suscribete a nuestra</p>
          <h4>Newsletter</h4>
          <GuestNewsletter />
        </Box>
      }
      copyright={
        <>
          <span>{footer?.copyright}</span>
          {footer?.legalLinks?.map((link) => (
            <Link key={link.title} href={link.url} color='textPrimary' underline='always'>
              {link.title}
            </Link>
          ))}
          <StoreSwitcherButton />
        </>
      }
      customerService={
        <Button href='/service' variant='pill'>
          <Trans id='Customer Service' />
        </Button>
      }
    />
  )
}
